<template>
  <content-with-sidebar class="cws-container cws-sidebar-right blog-wrapper">
    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card>
            <!-- eslint-disable vue/no-v-html -->
            <div class="blog-content" v-html="blogDetail.content" />
          </b-card>
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content --><!-- sidebar -->
    <div slot="sidebar" class="blog-sidebar py-2 py-lg-0">
      <!-- recent posts -->
      <div class="blog-recent-posts">
        <h6 class="section-label mb-75">Recent Posts</h6>
        <b-media
          v-for="(recentpost, index) in blogSidebar.recentPosts"
          :key="recentpost.img"
          no-body
          :class="index ? 'mt-2' : ''"
        >
          <b-media-aside class="mr-2">
            <b-link
              :to="{
                name: 'news-waste-detail',
                params: { id: recentpost.id },
              }"
            >
              <b-img
                :src="recentpost.img"
                :alt="recentpost.img.slice(6)"
                width="100"
                rounded
                height="70"
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link
                :to="{
                  name: 'news-waste-detail',
                  params: { id: recentpost.id },
                }"
                class="text-body-heading"
              >
                {{ recentpost.title }}
              </b-link>
            </h6>
            <span class="text-muted mb-0">
              {{ recentpost.createdTime }}
            </span>
          </b-media-body>
        </b-media>
      </div>
      <!--/ recent posts -->
    </div>
    <!--/ sidebar -->
  </content-with-sidebar>
</template>

<script>
import {
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";

export default {
  components: {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search_query: "",
      commentCheckmark: "",
      blogDetail: "",
      blogSidebar: {},
      socialShareIcons: [
        "GithubIcon",
        "GitlabIcon",
        "FacebookIcon",
        "TwitterIcon",
        "LinkedinIcon",
      ],
    };
  },
  created() {
    this.$http.get("/blog/list/data/detail").then((res) => {
      let blogList = res.data.blogPosts;
      let thisBlog = blogList.find(
        (o) => o.id === parseInt(this.$route.params.id)
      );
      this.blogDetail = thisBlog;
    });
    this.$http.get("/blog/list/data/sidebar").then((res) => {
      this.blogSidebar = res.data;
    });
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === "Quote") return "light-info";
      if (tag === "Gaming") return "light-danger";
      if (tag === "Fashion") return "light-primary";
      if (tag === "Video") return "light-warning";
      if (tag === "Food") return "light-success";
      return "light-primary";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
